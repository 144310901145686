
.MediaImagePicture{
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.36s ease-in-out;

  // border: 1px solid var(--secondary);
}

.MediaImagePicture:hover {
  box-shadow: 0px 0px 10px #2b219f45;
  .MediaImagePicture_picture,
  .MediaImagePicture_placeholder{
    transform: scale(1.05);
  }
}

.MediaImagePicture_picture{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.36s ease-in, transform 0.36s ease-in-out;
  will-change: opacity, transform;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.MediaImagePicture_placeholder{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  filter: blur(4px);
}

.MediaImagePicture_pointer{
  z-index: 2;
  width: 30px;
  height: 30px;
  padding-left:5px;
  padding-top: 5px;
  margin-left: -15px;
  margin-top: -15px;
  border-radius: 15px;
  background-color: var(--primary);
  pointer-events: none;
  box-shadow: 0 0 0px 2px var(--secondary);
}
