
.Home_paragraphsWrapper {
  // font-family: var(--font-family-serif);
  transition: padding .25s ease-in-out;
  margin: 5vh auto;
}

.Home_stepChapterWrapper {
  padding: 0px 10px 50px;
  margin-left: 20px;
  margin-right: 20px;
}
.Home_stepChapterWrapper a {
  text-decoration: none;
}
.Home_stepChapterWrapper a:hover {
  text-decoration: underline;
}

.Home_title{
  font-size: 3.4rem;
  line-height: 1.25em;
}
.Home_subheading{
  font-size: 1.5rem;
}
.Home_scrolldown{
  font-size: 1rem;
  position: relative;
  padding-right: 20px;
}


.Home_scrolldownIcon{
  position: absolute;
  top: 0;
  right: 0;
  animation-duration: 1.5s;
	animation-name: scrolldown;
	animation-iteration-count: infinite;
}

@keyframes scrolldown {
	0% {
		opacity: 0;
		transform: translate(0, -8px);
	}
	50% {
		opacity: 1;
		transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 8px);
	}
}
.Home_paragraphsWrapper h2{
  font-size: 1.25rem;
  text-align: left;
}

.Home_Footer, .Home_Footer .Footer_statec {
  margin-bottom: var(--spacer-5);
}
// -m
@media screen and (min-width: 30em) {
  .Home_paragraphsWrapper{
    margin: 10vh auto;
    width: 70%;
  }
  .Home_title{
    font-size:  8vw;
    line-height: 1em;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .Home_subheading{
    font-size: 2vw;
    line-height: 1.5rem;
  }
  .Home_scrolldown{
    font-size: 1.5vw;
  }
  .Home_paragraphsWrapper h2{
    font-size: 2vw;
    text-align: center;
  }
  .Home_stepChapterWrapper {
   padding: 25vh 10px;
    margin-left: 5vh;
    margin-right: 5vh;
  }
}
