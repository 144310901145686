.ZoomAndPanMedia{
  /* border: 1px solid; */
  background-color: rgba(0,0,0,.2);
}

.ZoomAndPanMedia_container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.ZoomAndPanMedia_container .ZoomAndPanMedia_zoomable {
  cursor: grab;
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 100%;
  vertical-align: middle;
  user-select: none;
}
.ZoomAndPanMedia_container .ZoomAndPanMedia_zoomable:active {
  cursor: grabbing;
}
.ZoomAndPanMedia_controls {
  position: absolute;
  bottom: -5px;
  left: -5px;
}

.ZoomAndPanMedia_controls .flex{
  background-color: var(--secondary);
  /* border: 1px solid;
  border-radius: 20px; */
}

.ZoomAndPanMedia_ControlsModal {
  position: absolute;
  bottom: 10px;
}

.ZoomAndPanMedia_zoomButton {
  border-radius: 40px;
  color: var(--black);
  background-color: var(--light-grey);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: pointer;
}

.ZoomAndPanMedia_zoomResetButton {
  background-color: var(--light-grey);
  border-radius: 40px;
  color: var(--black);
  padding: 12px 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  z-index: 100;

  cursor: pointer;
}
