
.About_Footer .Footer_statec {
  margin: var(--spacer-5) 0;
}
.About_Footer .Footer_unilu {
margin-left: -15px;
}


.About_switch{
  cursor: pointer;
  background-color: var(--secondary);
}
