
.TrendHotspot_point{
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -8px;
  width: 16px;
  height: 16px;
  background-color: var(--secondary);
  border-radius: 16px;
  pointer-events: auto;
}

.TrendHotspot_line{
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 10px;
  background-color: var(--secondary);
}

.TrendHotspot label{
  position: absolute;
  margin-left: 15px;
  width: 100px;
  top: 25px;
  margin-top: 5px;
  display: none;
  font-size: 13px;
  line-height: 20px;
}

.TrendHotspot.active{
  .TrendHotspot_line, .TrendHotspot_point{
    background-color: var(--accent);
  }
  label{
    display: block;
  }
}

.TrendPointers_focusKeys{
  // background-color: var(--secondary);
  // color: var(--primary);
}

.TrendPointers_legend{
  background-color: var(--secondary);
  color: var(--primary);
  transition: opacity 0.12s ease-in-out;
  border-radius: 3px;
  width: 250px;
  left: -125px;
  b{
    color: var(--white);
    font-family: inherit;
  }
}

.TrendPointers_legend_key_circle{
  position: relative;
  display: inline-block;
  width: 20px;

  &:after{
    position: absolute;
    top: -12px;
    left: 0;
    border-radius: 10px;
    content: '';
    width: 10px;
    height: 10px;
    background-color: inherit;
    border: 1px solid white;
  }
}
