@import 'fonts';

$fontFamilySans: 'HKGrotesk', Arial, sans-serif;
* {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: $fontFamilySans;
  height: 100%;
  // same as --primary
  background-color: #ffc09d;
  // same as --secondary
  color: #2b219f;
  //
  transition: background-color .6s ease-in-out;
  line-height: 1.5;
}
body.noscroll{
  overflow: hidden;
}
:root {
  --primary: #ffc09d;
  --secondary: #2b219f;
  --tertiary: #103c20;
  --white: #ffffff;
  --accent: #d03a45;
  --dark: #2b219f;
  --font-family-serif: 'SneakyTimes', serif;
  --font-family-sans: $fontFamilySans;
  --spacer-1: 5px;
  --spacer-2: 10px;
  --spacer-3: 15px;
  --spacer-4: 20px;
  --spacer-5: 25px;
  --spacer-6: 30px;
  --spacer-7: 35px;
  --spacer-8: 40px;
  --spacer-9: 45px;
  --spacer-10: 50px;
  --negative-spacer-1: -5px;
  --negative-spacer-2: -10px;
  --negative-spacer-3: -15px;
  --negative-spacer-4: -20px;
  --negative-spacer-5: -25px;
  --negative-spacer-6: -30px;
  --negative-spacer-7: -35px;
  --negative-spacer-8: -40px;
  --negative-spacer-9: -45px;
  --negative-spacer-10: -50px;
  --font-size-1: 10px;
  --font-size-2: 12px;
  --t2-colorkeys-primary: #d03a45;
  --t2-colorkeys-secondary: #2b219f;
  --t2-colorkeys-tertiary: #357DED;
  --t1-colorkeys-primary: #d03a45;
  --t1-colorkeys-secondary: #2b219f;
  --t1-colorkeys-tertiary: #357DED;
  // graph in narrative
  --dataset-t2-v: #1A1D1A;
  --dataset-t2-v1: #009FD0; //#E03616;
  --dataset-t2-v2: #20A39E; // complemenrary
  // --accent
  --datakey-v: #d03a45;
  //
  --datakey-v1: #5FAD41;
  // filckr pink
  --datakey-v2: #CCF5AC;
  --data-background: #86b9d4;
  --zindex--cookieconsent: 1000;
}
// chapter colors
:root {
  --theme-01: #bfe8ed;
  --theme-02: #b4ebc8;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  color: var(--secondary);
  // font-family: 'SneakyTimes', serif;
  font-weight: 100;
}

a {
  color: var(--secondary);
}
.sans {
  font-family: $fontFamilySans;
}

.bold{
  // font-family: Arial;
  font-weight: bold;
  // based on sans...
  font-size: inherit;
}
.loading-spinner {
  animation: rotate 1.5s linear infinite;
}

.colorpoint{
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary);
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}

.loader {
  animation: rotate 2s linear infinite;
}

.scrollable{
  overflow: scroll;
}
.with-vertical-line:after{
  position: absolute;
  content: '';
  width: 1px;
  left: 50%;
  background: var(--secondary);
  height: 15vh;
  bottom: -10vh;
}
.anchor{
  display: block;
  position: relative;
  top: -200px;
  visibility: hidden;
}

.extra-border{
  position: relative;
  border-radius: 7px;
}
.extra-border::after {
  position: absolute;
  top: 2px;
  right: -.25rem;
  bottom: -.25rem;
  left: 2px;
  border: 1px solid;
  border-radius: 8px;
  z-index: -1;
  content: "";
  opacity: .47;
}
