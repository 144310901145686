@font-face {
  font-family: 'HKGrotesk';
  src: url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.woff2') format('woff2'),
    url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.woff') format('woff'),
    url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk';
  src: url('../assets/fonts/hk-grotesk/HKGrotesk-Bold.woff2') format('woff2'),
    url('../assets/fonts/hk-grotesk/HKGrotesk-Bold.woff') format('woff'),
    url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk';
  src: url('../assets/fonts/hk-grotesk/HKGrotesk-Italic.woff2') format('woff2'),
    url('../assets/fonts/hk-grotesk/HKGrotesk-Italic.woff') format('woff'),
    url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* @font-face {
  font-family: 'Porpora';
  src: url('../assets/fonts/porpora/Porpora-Regular.woff2') format('woff2'),
    url('../assets/fonts/porpora/Porpora-Regular.woff') format('woff'),
    url('../assets/fonts/porpora/Porpora-Regular.ttf') format('truetype');
  font-weight: regular;
} */

@font-face {
  font-family: 'SneakyTimes';
  src: url('../assets/fonts/sneakyTimes/Sneaky-Times.woff2') format('woff2'),
    url('../assets/fonts/sneakyTimes/Sneaky-Times.woff') format('woff');
  font-weight: regular;
}
